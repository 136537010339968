body {
  margin: 0;
  background-color: rgb(245, 245, 245);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tescoLogo {
  height: 70px;
  width: 175px;
}

.ceLogo {
  height: 70px;
  width: 175px;
  right: 0;
  position: absolute;
}

.paperGrid {
  height: 100%;
}

.details {
  border-left: solid;
  border-left-color:#007FAE;
  border-left-width: 3px;
}

.banner {
  height: 25px;
  text-align: center;
  background-color: red;
  color: white;
}

.footer {
  position: absolute;
  bottom: 0
}

.link {
  padding-right: 20px;
  color: white;
  text-decoration: none;
}

/* Tesco branding */
@font-face{font-family:tesco modern;font-display:swap;font-weight:400;
  src: url("/fonts/tescomodreg-web.woff2") format("woff2"),
  url("/fonts/tescomodreg-web.woff") format("woff"),
  url("/fonts/TESCOModern-Regular-final.ttf") format("truetype"),
  url("/fonts/tescomodreg-web.eot") format("embedded-opentype")}
  
  @font-face{font-family:tesco modern;font-display:swap;font-weight:700;
    src:url("/fonts/tescomodbol-web.woff2") format("woff2"),
    url("/fonts/tescomodbol-web.woff") format("woff"),
    url("/fonts/TESCOModern-Bold.ttf") format("truetype"),
    url("/fonts/tescomodbol-web.eot") format("embedded-opentype")}
    

.MuiButton-containedPrimary {
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "tesco modern" !important;
  border-radius: 25px !important;
  color: #F7F5F5 !important;
}

.MuiButton-startIcon {
  margin-right: 0 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #00539F !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #91BAE3 !important;
}

.MuiSelect-icon {
  top: auto !important;
}

.pageTitles {
  font-size: 32px !important;
  letter-spacing: -0.8px !important;
  font-family: "tesco modern" !important;
  color:  #575252 !important;
  font-weight: bold !important;
}

.pageSubTitles {
  font-size: 28px !important;
  letter-spacing: -0.4px !important;
  font-family: "tesco modern" !important;
  color:  #575252 !important;
  font-weight: bold !important;
}

.headings {
  font-size: 20px !important;
  letter-spacing: -0px !important;
  font-family: "tesco modern"!important;
  color:  #575252 !important;
  font-weight: bold !important;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "tesco modern" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.links {
  color: #00539F !important;
  font-weight: bold;
}

.bodyText {
  font-size: 18px;
  font-family: "tesco modern";
  color: #575252;
  line-height: 1.5;

}

.MuiSelect-iconOutlined {
  color: #91BAE3 !important;
}

.tescoPaper {
  box-shadow: '2px 3px 8px 0 rgba(0, 0, 0, .2)';
  border-radius: '6px';
}
.MuiButton-root {
  text-transform: none !important;
}

.MuiFormLabel-root {
  font-family: "tesco modern" !important;
  color: #575252 !important;
  line-height: 1.5 !important;
}

.MuiSelect-root {
  font-family: "tesco modern" !important;
  color: #575252 !important;
  line-height: 1.5 !important;
}

.MuiInputBase-input {
  font-family: "tesco modern" !important;
  color: #575252 !important;
  line-height: 1.5 !important;
}

.MuiSelect-select.Mui-disabled {
  cursor: not-allowed;
  background: #e1e4eaa3;
}